import { t } from "react-multi-lang";
import _ from 'lodash';
import { format } from "date-fns";

import * as accountActions from '../actions/accountAction';
import { IReducerAction, IReducer, IPayloadObj } from './_commonInterface';
import { ACCESS_ROLES } from 'src/constants/userRoles';
import { getRegistrationStatus } from 'src/constants/registrationStatus';
import { getRegistrationUserType } from 'src/constants/registrationUserType';

import { toDayMonthYear } from 'src/helpers/datetimeHelper';
import { STATUSTYPE_AS_NAME, STATUS_NAME } from 'src/constants/status';
import { BusinessTypeName, BusinessTypeAsId, businessTypeHelper } from 'src/constants/businessType';
import State from 'src/constants/state';
import { pendingCAStatusCode } from "src/constants/pendingCAStatusCode";

interface IGroupDetails {
  groupId: string;
  groupName: string;
  totalAccounts: number;
  accountsTable: object[];
  accountsCount: number;
}

interface IGroups {
  groupsTable: object[];
  groupsCount: number;
  noGroupAccountsTable: object[];
  noGroupAccountsCount: number;
  groupDetails: IGroupDetails;
}

interface IAddAccounts {
  autoDetectedTable: object[];
  manualAddTable: object[];
  uploadAccounts: object[];
  validatedAccount: {
    accountNo: string;
    errorType: string;
  };
  noOfSuccessAccount: number;
}

interface IPendingCA {
  pendingAccTable: object[];
  pendingAccCount: number;
  validatedAccount: {
    accountNo: string;
    errorType: string;
  };
}

interface IManageAccounts {
  bulkAccountDetails: object;
  accountDetails: object;
  topCards: object;
  groups: IGroups;
  addAccounts: IAddAccounts;
  pendingCA: IPendingCA;
}

interface IAccountReducer {
  forgotPassword: object,
  resetPassword: object,
  myProfile: object,
  isMasterAccount: boolean,
  manageAccounts: IManageAccounts,
  changePassword: object,
  register: object,
  registerError: object,
  bulkAccountRegistration: object,
  bulkRegistrationApplication: object,
  bulkPostRegistration: object,
  activateAccount: object,
  resendActivationLink: object,
  emailFromInvitationToken: NullableNumberString,
  registrationUserType: NullableNumberString,
}
interface IUpdateFavDates {
  startDate: string;
  endDate: string;
}

interface IgetFavDates {
  startDate: string;
  endDate: string;
}

type NullableNumberString = number | string | null;

const defaultState: IAccountReducer = {
  forgotPassword: {
  },
  resetPassword: {
    isExpired: false,
    isInvalid: false,
    isUsed: false,
  },
  myProfile: {
    companyName: "",
    sectorName: "",
  },
  isMasterAccount: false,
  manageAccounts: {
    bulkAccountDetails: {},
    accountDetails: {},
    topCards: {},
    groups: {
      groupsTable: [],
      groupsCount: 0,
      noGroupAccountsTable: [],
      noGroupAccountsCount: 0,
      groupDetails: {
        groupId: "",
        groupName: "",
        totalAccounts: 0,
        accountsTable: [],
        accountsCount: 0,
      },
    },
    addAccounts: {
      autoDetectedTable: [],
      manualAddTable: [],
      uploadAccounts: [],
      validatedAccount: {
        accountNo: "",
        errorType: "",
      },
      noOfSuccessAccount: 0,
    },
    pendingCA: {
      pendingAccTable: [],
      pendingAccCount: 0,
      validatedAccount: {
        accountNo: "",
        errorType: "",
      },
    },
  },
  changePassword: {
    errorType: "",
  },
  register: {
    email: "",
    password: "",
    name: "",
    identificationType: "",
    identificationNo: "",
    mobileCode: "",
    mobileNo: "",
    showAnnouncement: true,
  },
  registerError: {
    isNotUniqueEmail: false,
    isInvalidFormat: false,
  },
  bulkAccountRegistration: {
    activeStep: 0,
    activeForm: 0,
    businessType: "",
    companyName: "",
    companyNo: "",
    mobileNo: "",
    mailUnitFloor: "",
    mailBuildingName: "",
    mailStreetName: "",
    mailCity: "",
    mailPostal: "",
    mailState: "",
    mailStateValue: "",
    primaryContactName: "",
    primaryContactEmail: "",
    primaryContactMobileNo: "",
    primaryContactJob: "",
    primaryContactDepartment: "",
    secondaryContactName: "",
    secondaryContactEmail: "",
    secondaryContactMobileNo: "",
    secondaryContactJob: "",
    secondaryContactDepartment: "",
    rocList: [],
    noAddAccounts: [],
    manualAccounts: [],
    savedManualAccounts: [],
    autoAccounts: [],
    declarationFileName: "",
    declarationFileDatetime: ""
  },
  bulkRegistrationApplication: {},
  bulkPostRegistration: {
    errorAccounts: [],
    nonErrorAccounts: []
  },
  activateAccount: {
    invalidToken: false,
    activatedUser: false,
    tokenExpired: false,
  },
  resendActivationLink: {
    invalidToken: false,
    activatedUser: false,
  },
  emailFromInvitationToken: null,
  registrationUserType: null,
}

const REDUCERS: IReducer = {
  [accountActions.SET_IS_VALID_USER]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      forgotPassword: {
        ...state.forgotPassword,
        isValidUser: payload,
      }
    }
  },
  [accountActions.GET_USER_PROFILE]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      myProfile: {
        ...state.myProfile,
        ...payload,
        lastLoginDateTime: _.isNull(payload.lastLoginDateTime) ? "-" : payload.lastLoginDateTime + "Z",
      },
      isMasterAccount: _.includes(_.get(payload, 'scopes'), ACCESS_ROLES.USER.MANAGE_USER.UPDATE),
    }
  },
  [accountActions.USER_TUTORIAL_COMPLETED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const tutorials =_.filter(_.get(state.myProfile, "tutorials"), (t: any) => t.userTutorialId !== payload);

    return {
      ...state,
      myProfile: {
        ...state.myProfile,
        tutorials,
      }
    }
  },
  [accountActions.SET_USER_PROFILE_BY_ADMIN]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      myProfile: {
        ...state.myProfile,
        ...payload,
        lastLoginDateTime: _.isNull(payload.lastLoginDateTime) ? "-" : payload.lastLoginDateTime + "Z",
      },
      isMasterAccount: true,
    }
  },
  [accountActions.RESET_PASSWORD_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      resetPassword: {
        ...state.resetPassword,
        isExpired: payload.isExpired,
        isInvalid: payload.isInvalid,
        isUsed: payload.isUsed,
      }
    }
  },
  [accountActions.SEND_FORGOT_PASSWORD_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      forgotPassword: {
        ...state.forgotPassword,
        isValidUser: payload.isValidUser,
        errorMessage: payload.errorMessage,
      }
    }
  },
  [accountActions.VALIDATE_RESET_TOKEN_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      resetPassword: {
        ...state.resetPassword,
        isExpired: payload.isExpired,
        isInvalid: payload.isInvalid,
        isUsed: payload.isUsed,
      }
    }
  },
  [accountActions.CHANGE_PASSWORD]: (state: IAccountReducer) => {
    return {
      ...state,
      changePassword: {
        ...state.changePassword,
        errorType: "",
      }
    }
  },
  [accountActions.CHANGE_PASSWORD_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      changePassword: {
        ...state.changePassword,
        errorType: payload.errorMessage,
      }
    }
  },
  [accountActions.GET_PROFILE_FOR_BULK_ID]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const mailState = _.get(_.find(State.malaysiaAccount, (s:any) => t(s.value) === _.get(payload, "state")), "code");
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        bulkAccountDetails: {
          bulkId: _.get(payload, "collectiveContractAccountNo"),
          companyName: _.get(payload, "name"),
          registrationNo: _.get(payload, "registrationNo"),
          companyLandline: _.get(payload, "companyLandline"),
          createdDate: _.get(payload, "registeredDate") ? toDayMonthYear(payload.registeredDate) : null,
          primaryName: _.get(payload, "userName"),
          primaryEmail: _.get(payload, "email"),
          primaryMobile: _.get(payload, "mobileNo"),
          primaryJobTitle: _.get(payload, "jobTitle"),
          primaryDepartment: _.get(payload, "departmentName"),
          secondaryName: _.get(payload, "secondary_UserName"),
          secondaryEmail: _.get(payload, "secondary_Email"),
          secondaryMobile: _.get(payload, "secondary_MobileNo"),
          secondaryJobTitle: _.get(payload, "secondary_JobTitle"),
          secondaryDepartment: _.get(payload, "secondary_DepartmentName"),
          mailingAddr1: _.get(payload, "mailingAddr1"),
          mailingAddr2: _.get(payload, "mailingAddr2"),
          mailingAddr3: _.get(payload, "mailingAddr3"),
          unitFloor: _.get(payload, "unitNo"),
          building: _.get(payload, "building"),
          street: _.get(payload, "street"),
          city: _.get(payload, "city"),
          postalCode: _.get(payload, "postcode"),
          state: mailState,
          businessType: _.get(payload, "businessTypeId") ? businessTypeHelper(payload.businessTypeId) : undefined,
        },
      }
    }
  },
  [accountActions.GET_ACCOUNT_FOR_BULK_ID]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { collectiveChildAccounts } = payload;
    _.forEach(collectiveChildAccounts, c => {
      _.set(c, "accountNo", _.get(c, "accountNumber"));
      _.set(c, "group", _.get(c, "groupName"));
      _.set(c, "companyName", _.get(c, "accountDescription"));
      /*
        Status:
        true = Active
        false = Inactive
      */
      _.set(c, "activeStatus", c.status ? STATUSTYPE_AS_NAME[STATUS_NAME.ACTIVE].code : STATUSTYPE_AS_NAME[STATUS_NAME.INACTIVE].code);
      delete c["accountNumber"];
      delete c["accountDescription"];
      delete c["status"];
      delete c["businessArea"];
      delete c["groupName"];
    });
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        collectiveChildAccounts,
      }
    }
  },
  [accountActions.GET_ELECTRICITY_ACCOUNT_DETAILS]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        accountDetails: {
          accountNo: _.get(payload, "accountNumber"),
          referenceId: _.get(payload, "referenceId"),
          group: _.get(payload, "groupName"),
          groupId: _.get(payload, "groupId"),
          accountStatus: payload.status ? STATUSTYPE_AS_NAME[STATUS_NAME.ACTIVE].code : STATUSTYPE_AS_NAME[STATUS_NAME.INACTIVE].code,
          companyName: _.get(payload, "accountDescription"),
          premisesAddress: _.get(payload, "address"),
          state: _.get(payload, "state"),
          bulkId: _.get(payload, "bulkId"),
        }
      }
    }
  },
  [accountActions.SET_REGISTER_STATE]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      register: {
        ...state.register,
        ...payload,
      }
    }
  },
  [accountActions.SET_REGISTER_ANNOUNCEMENT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      register: {
        ...state.register,
        showAnnouncement: payload.showAnnouncement,
      }
    }
  },
  [accountActions.REGISTER_STATE_RESET]: (state: IAccountReducer) => {
    return {
      ...state,
      register: {
        email: "",
        password: "",
        activationToken: undefined,
        showAnnouncement: true,
      },
      registerError: {
        isNotUniqueEmail: false,
        isInvalidFormat: false,
      }
    }
  },
  [accountActions.SET_BULKACCOUNTREGISTRATION_FORM]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { form } = payload;

    return {
      ...state,
      bulkAccountRegistration: form
    }
  },
  [accountActions.GET_BULKIDBYROC]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      bulkAccountRegistration: {
        ...state.bulkAccountRegistration,
        rocList: payload
      }
    }
  },
  [accountActions.IS_NEW_EMAIL_VALID_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { isNotUniqueEmail, isInvalidFormat } = payload;

    return {
      ...state,
      registerError: {
        isNotUniqueEmail,
        isInvalidFormat,
      }
    }
  },
  [accountActions.REGISTER_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { invalidEmail, invalidName, invalidIdentificationNo, invalidIdentificationType, invalidMobileNo, invalidPassword, unknownError } = payload;

    return {
      ...state,
      registerError: {
        ...state.registerError,
        invalidEmail,
        invalidName,
        invalidIdentificationNo,
        invalidIdentificationType,
        invalidMobileNo,
        invalidPassword,
        unknownError,
      }
    }
  },
  [accountActions.VALIDATE_ACTIVATE_ACCOUNT_TOKEN_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { invalidToken, activatedUser, tokenExpired } = payload;
    return {
      ...state,
      activateAccount: {
        invalidToken,
        activatedUser,
        tokenExpired,
      }
    }
  },
  [accountActions.RESEND_ACTIVATE_Link_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { invalidToken, activatedUser } = payload;
    return {
      ...state,
      resendActivationLink: {
        invalidToken,
        activatedUser,
      }
    }
  },
  [accountActions.GET_EMAIL_BY_INVITATION_TOKEN]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      emailFromInvitationToken: payload,
    }
  },
  [accountActions.GET_ELECTRICITY_ACCOUNTS_CARD]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        topCards: {
          totalAccounts: _.get(payload, "totalAccounts") ?? "-",
          activeAccounts: _.get(payload, "activeAccounts") ?? "-",
          inactiveAccounts: _.get(payload, "inactiveAccounts") ?? "-",
        }
      }
    }
  },
  [accountActions.ADD_MANUALACCOUNTS]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const {accountNo, referenceId} = payload;
    const manualAccounts = _.get(state.bulkAccountRegistration, "manualAccounts", []);
    return {
      ...state,
      bulkAccountRegistration: {
        ...state.bulkAccountRegistration,
        manualAccounts: manualAccounts.concat({
          uniqueId: _.uniqueId(),
          accountNo,
          referenceId
        })
      }
    }
  },
  [accountActions.UPDATE_MANUALACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const {uniqueId, propertyName, propertyValue, errorTypeList} = payload;
    const newManualAccounts = _.cloneDeep(_.get(state.bulkAccountRegistration, "manualAccounts", []));
    const targetIndex = _.findIndex(newManualAccounts, (m:any) => m.uniqueId === uniqueId);

    if (targetIndex > -1) {
      _.set(newManualAccounts[targetIndex], propertyName, propertyValue);
      _.set(newManualAccounts[targetIndex], `${propertyName}HasError`, !_.isEmpty(errorTypeList));
      _.set(newManualAccounts[targetIndex], `${propertyName}ErrorTypes`, errorTypeList);
      _.unset(newManualAccounts[targetIndex], `${propertyName}IsLoading`);
    }

    return {
      ...state,
      bulkAccountRegistration: {
        ...state.bulkAccountRegistration,
        manualAccounts: newManualAccounts,
      }
    }
  },
  [accountActions.UPDATE_MANUALACCOUNT_NOVALIDATION]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const {uniqueId, propertyName, propertyValue, errorTypes, shouldLoad} = payload;
    const newManualAccounts = _.cloneDeep(_.get(state.bulkAccountRegistration, "manualAccounts", []));
    const targetIndex = _.findIndex(newManualAccounts, (m:any) => m.uniqueId === uniqueId);

    if (targetIndex > -1) {
      _.set(newManualAccounts[targetIndex], propertyName, propertyValue)

      if (!_.isEmpty(errorTypes)) {
        _.set(newManualAccounts[targetIndex], `${propertyName}HasError`, true);
        _.set(newManualAccounts[targetIndex], `${propertyName}ErrorTypes`, errorTypes);
      }
      else {
        _.unset(newManualAccounts[targetIndex], `${propertyName}HasError`);
        _.unset(newManualAccounts[targetIndex], `${propertyName}ErrorTypes`);
      }

      if (shouldLoad)
        _.set(newManualAccounts[targetIndex], `${propertyName}IsLoading`, true);
    }

    return {
      ...state,
      bulkAccountRegistration: {
        ...state.bulkAccountRegistration,
        manualAccounts: newManualAccounts,
      }
    }
  },
  [accountActions.UPLOAD_MANUALACCOUNTS]: (state: IAccountReducer, { payload }: IPayloadObj) => {      
    const manualAccounts = _.map(payload, p => {
      const autoAccounts = _.get(state.bulkAccountRegistration, "autoAccounts", []);
      if (autoAccounts.length > 0 && _.findIndex(autoAccounts, (a:any) => a.accountNo === p.accountNumber) > -1)
        return {
          uniqueId: _.uniqueId(),
          accountNo: p.accountNumber,
          referenceId: p.referenceID,
          accountNoHasError: true,
          accountNoErrorTypes: ["modules.bulkAccount.registration.errorOfDuplicate", ...p.errorTypeList]
        };
      else
        return {
          uniqueId: _.uniqueId(),
          accountNo: p.accountNumber,
          referenceId: p.referenceID,
          accountNoHasError: !_.isEmpty(p.errorTypeList),
          accountNoErrorTypes: p.errorTypeList
        };
  });
    
    return {
      ...state,
      bulkAccountRegistration: {
        ...state.bulkAccountRegistration,
        manualAccounts: manualAccounts
      }
    }
  },
  [accountActions.CLEAR_MANUALACCOUNTS]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const savedManualAccounts = _.clone(_.get(state.bulkAccountRegistration, "savedManualAccounts", []));
    return {
      ...state,
      bulkAccountRegistration: {
        ...state.bulkAccountRegistration,
        manualAccounts: savedManualAccounts
      }
    }
  },
  [accountActions.SAVE_MANUALACCOUNTS]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const manualAccounts = _.clone(_.get(state.bulkAccountRegistration, "manualAccounts", []));
    return {
      ...state,
      bulkAccountRegistration: {
        ...state.bulkAccountRegistration,
        savedManualAccounts: manualAccounts
      }
    }
  },
  [accountActions.REMOVE_MANUALACCOUNTS]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const manualAccounts = _.get(state.bulkAccountRegistration, "manualAccounts", []);
    const targetIndex = _.findIndex(manualAccounts, (m:any) => m.uniqueId === payload.uniqueId);

    if (targetIndex > -1)
      manualAccounts.splice(targetIndex, 1);

    return {
      ...state,
      bulkAccountRegistration: {
        ...state.bulkAccountRegistration,
        manualAccounts: manualAccounts
      }
    }
  },
  [accountActions.GET_AUTO_SUGGESTIVE_ACCOUNTS]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accounts, accountsWithError } = payload;

    return {
      ...state,
      bulkAccountRegistration: {
        ...state.bulkAccountRegistration,
        autoAccounts: [...accounts],
        noAddAccounts: [...accountsWithError],
      }
    };
  },
  [accountActions.UPDATE_AUTOACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const {accountNo, propertyName, propertyValue, hasError} = payload;
    const autoAccounts = _.get(state.bulkAccountRegistration, "autoAccounts", []);
    const targetIndex = _.findIndex(autoAccounts, (m:any) => m.accountNo === accountNo);
    _.set(state.bulkAccountRegistration, `autoAccounts[${targetIndex}].${propertyName}`, propertyValue);

    if (hasError !== undefined)
      _.set(state.bulkAccountRegistration, `autoAccounts[${targetIndex}].${propertyName}HasError`, hasError);

    return {
      ...state,
      bulkAccountRegistration: {
        ...state.bulkAccountRegistration,
        autoAccounts: _.get(state.bulkAccountRegistration, "autoAccounts")
      }
    }
  },
  [accountActions.SET_SELECTED_AUTOACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accountNo, isSelect } = payload;
    const autoAccounts = _.get(state.bulkAccountRegistration, "autoAccounts", []);

    const targetRow = _.find(autoAccounts, a => _.get(a, 'accountNo') === accountNo);
    if (targetRow)
      _.set(targetRow, "isSelected", isSelect);

    return {
      ...state,
      bulkAccountRegistration: {
        ...state.bulkAccountRegistration,
        autoAccounts: _.get(state.bulkAccountRegistration, "autoAccounts")
      }
    };
  },
  [accountActions.UPLOAD_DECLARATIONFORM]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      bulkAccountRegistration: {
        ...state.bulkAccountRegistration,
        declarationFileName: _.get(payload, "fileName"),
        declarationFileDatetime: _.get(payload, "fileUploadDateTime")
      }
    };
  },
  [accountActions.REGISTER_BULKACCOUNT_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      bulkAccountRegistration: {
        ...state.bulkAccountRegistration,
        registerErrorMessage: _.get(payload, 'errorMessage'),
        registerErrorTopic: _.get(payload, 'errorTopic')
      }
    };
  },
  [accountActions.UPDATE_REGISTRATION_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      bulkAccountRegistration: {
        ...state.bulkAccountRegistration,
        registerErrorMessage: _.get(payload, 'errorMessage'),
        registerErrorTopic: _.get(payload, 'errorTopic')
      }
    };
  },
  [accountActions.GET_BULKREGISTRATIONSTATUS]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      myProfile: {
        ...state.myProfile,
        bulkRegistrationStatus: !_.isNull(payload.statusCode) ? getRegistrationStatus(payload.statusCode) : getRegistrationStatus(7), //treating no registrationstatus as approve until BE confirm data patching
        hasValidContractAccount: _.get(payload, "hasValidContractAccount", true)
      },
    };
  },
  [accountActions.EDIT_BULK_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const mailState = _.get(_.find(State.malaysiaAccount, (s:any) => t(s.value) === _.get(payload, "state")), "code");
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        bulkAccountDetails: {
          bulkId: _.get(payload, "collectiveContractAccountNo"),
          companyName: _.get(payload, "name"),
          registrationNo: _.get(payload, "registrationNo"),
          companyLandline: _.get(payload, "companyLandline"),
          createdDate: _.get(payload, "createdDate") ? toDayMonthYear(payload.createdDate) : null,
          primaryName: _.get(payload, "userName"),
          primaryEmail: _.get(payload, "email"),
          primaryMobile: _.get(payload, "mobileNo"),
          primaryJobTitle: _.get(payload, "jobTitle"),
          primaryDepartment: _.get(payload, "departmentName"),
          secondaryName: _.get(payload, "secondary_UserName"),
          secondaryEmail: _.get(payload, "secondary_Email"),
          secondaryMobile: _.get(payload, "secondary_MobileNo"),
          secondaryJobTitle: _.get(payload, "secondary_JobTitle"),
          secondaryDepartment: _.get(payload, "secondary_DepartmentName"),
          mailingAddr1: _.get(payload, "mailingAddr1"),
          mailingAddr2: _.get(payload, "mailingAddr2"),
          mailingAddr3: _.get(payload, "mailingAddr3"),
          unitFloor: _.get(payload, "unitNo"),
          building: _.get(payload, "building"),
          street: _.get(payload, "street"),
          city: _.get(payload, "city"),
          postalCode: _.get(payload, "postcode"),
          state: mailState,
        },
      }
    }
  },
  [accountActions.EDIT_BULK_ACCOUNT_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        errorTopic: _.get(payload, "errorTopic"),
        errorMessage: _.get(payload, "errorMessage"),
      }
    }
  },
  [accountActions.REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        noOfRemovedAccounts: payload,
      }
    }
  },
  [accountActions.REMOVE_CHILD_ACCOUNT_FROM_BULK_ACCOUNT_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        errorTopic: _.get(payload, "errorTopic"),
        errorMessage: _.get(payload, "errorMessage"),
      }
    }
  },
  [accountActions.GET_COMPANY_NAME_FOR_BULK_ID]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      myProfile: {
        ...state.myProfile,
        companyName: payload,
      },
    }
  },
  [accountActions.GET_BUSINESS_TYPE_FOR_BULK_ID]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      myProfile: {
        ...state.myProfile,
        sectorName: payload,
      },
    }
  },
  [accountActions.UPDATE_ERRORACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const {uniqueId, propertyName, propertyValue} = payload;
    const pendingAccTable = _.get(state.manageAccounts.pendingCA, "pendingAccTable", []);
    const targetIndex = _.findIndex(pendingAccTable, (e:any) => e.uniqueId === uniqueId);

    if (targetIndex > -1) {
      _.set(state.manageAccounts.pendingCA, `pendingAccTable[${targetIndex}].${propertyName}`, propertyValue);
    }

    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        pendingCA: {
          ...state.manageAccounts.pendingCA,
          pendingAccTable: _.get(state.manageAccounts.pendingCA, "pendingAccTable"),
        },
      }
    }
  },
  [accountActions.GET_REGISTRATIONAPPLICATION]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      bulkRegistrationApplication: {
        ...payload,
        submittedDate: _.isNull(payload.submittedDate) ? "-" : payload.submittedDate + "Z",
        status: payload.statusCode ? getRegistrationStatus(payload.statusCode) : "-",
        userType: payload.userType ? getRegistrationUserType(payload.userType) : "-",
      }
    }
  },
  [accountActions.GET_REGISTRATIONRECORD]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { bulkUserRegistration, bulkUserRegistrationDetails } = payload;

    const mailState = _.get(_.find(State.malaysiaAccount, (s:any) => t(s.value) === bulkUserRegistration.state), "code");
    
    return {
      ...state,
      bulkAccountRegistration: {
        isEdit: true,
        activeStep: 0,
        activeForm: BusinessTypeAsId[bulkUserRegistration.businessType].key === BusinessTypeName.PRIVATE ? 0 : 1,
        businessType: BusinessTypeAsId[bulkUserRegistration.businessType].key,
        companyName: bulkUserRegistration.companyName,
        mobileNo: bulkUserRegistration.companyLandline,
        mailUnitFloor: bulkUserRegistration.unitFloorNo,
        mailBuildingName: bulkUserRegistration.buildingName,
        mailStreetName: bulkUserRegistration.streetName,
        mailCity: bulkUserRegistration.city,
        mailPostal: bulkUserRegistration.postalCode,
        mailState: mailState,
        mailStateValue: bulkUserRegistration.state,
        primaryContactName: bulkUserRegistration.primaryName,
        primaryContactEmail: bulkUserRegistration.primaryEmail,
        primaryContactMobileNo: bulkUserRegistration.primaryMobileNo,
        primaryContactJob: bulkUserRegistration.primaryJobTitle,
        primaryContactDepartment: bulkUserRegistration.primaryDepartment,
        secondaryContactName: bulkUserRegistration.secondaryName,
        secondaryContactEmail: bulkUserRegistration.secondaryEmail,
        secondaryContactMobileNo: bulkUserRegistration.secondaryMobileNo,
        secondaryContactJob: bulkUserRegistration.secondaryJobTitle,
        secondaryContactDepartment: bulkUserRegistration.secondaryDepartment,
        companyNo: bulkUserRegistration.rocNumber,
        declarationFileName: bulkUserRegistration.declarationFileName,
        declarationFileDatetime: bulkUserRegistration.declarationFileUploadDateTime ? bulkUserRegistration.declarationFileUploadDateTime + "Z" : "",
        electricityAccounts: bulkUserRegistrationDetails
      }
    }
  },
  [accountActions.SET_SELECTED_ERRORACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { uniqueId, isSelect } = payload;
    const pendingAccTable = _.get(state.manageAccounts.pendingCA, "pendingAccTable", []);

    const targetRow = _.find(pendingAccTable, a => _.get(a, 'uniqueId') === uniqueId);
    if (targetRow)
      _.set(targetRow, "isSelected", isSelect);

    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        pendingCA: {
          ...state.manageAccounts.pendingCA,
          pendingAccTable: _.get(state.manageAccounts.pendingCA, "pendingAccTable"),
        },
      }
    }
  },
  [accountActions.GET_GROUPS_TABLE_FOR_BULK_ID]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { groups, count } = payload;
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          groupsTable: [...groups],
          groupsCount: _.isNull(count) ? _.get(groups, 'length') : count,
        },
      }
    }
  },
  [accountActions.SET_SELECTED_GROUPS]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { groupId, isSelect } = payload;
    
    const targetRow = _.find(state.manageAccounts.groups.groupsTable, g => _.get(g, 'groupId') === groupId);
    if (targetRow)
      _.set(targetRow, "isSelected", isSelect);

    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          groupsTable: [...state.manageAccounts.groups.groupsTable],
        },
      }
    };
  },
  [accountActions.GET_ACCOUNTS_NOT_IN_GROUP]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accounts, count } = payload;
    _.forEach(accounts, c => {
      _.set(c, "accountNo", _.get(c, "accountNumber"));
      _.set(c, "companyName", _.get(c, "accountDescription"));
      /*
        Status:
        true = Active
        false = Inactive
      */
      _.set(c, "activeStatus", c.status ? STATUSTYPE_AS_NAME[STATUS_NAME.ACTIVE].code : STATUSTYPE_AS_NAME[STATUS_NAME.INACTIVE].code);
      delete c["accountNumber"];
      delete c["accountDescription"];
      delete c["status"];
      delete c["businessArea"];
      delete c["groupName"];
      delete c["groupId"];
      delete c["sectorId"];
    });
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          noGroupAccountsTable: accounts,
          noGroupAccountsCount: _.isNull(count) ? _.get(accounts, 'length') : count,
        },
      }
    }
  },
  [accountActions.SET_SELECTED_ACCOUNTS]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accountNo, isSelect } = payload;
    
    const targetRow = _.find(state.manageAccounts.groups.noGroupAccountsTable, g => _.get(g, 'accountNo') === accountNo);
    if (targetRow)
      _.set(targetRow, "isSelected", isSelect);

    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          noGroupAccountsTable: [...state.manageAccounts.groups.noGroupAccountsTable],
        },
      }
    };
  },
  [accountActions.CREATE_GROUP]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          newGroupId: _.get(payload, "groupId"),
        },
      }
    }
  },
  [accountActions.CREATE_GROUP_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          errorTopic: _.get(payload, "errorTopic"),
          errorMessage: _.get(payload, "errorMessage"),
        },
      }
    }
  },
  [accountActions.GET_GROUP_DETAILS]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          groupDetails: {
            ...state.manageAccounts.groups.groupDetails,
            groupId: _.get(payload, "groupId"),
            groupName: _.get(payload, "groupName"),
            totalAccounts: _.get(payload, "totalAccounts"),
          }
        },
      }
    }
  },
  [accountActions.GET_GROUP_DETAILS_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          groupDetails: {
            ...state.manageAccounts.groups.groupDetails,
            errorTopic: _.get(payload, "errorTopic"),
            errorMessage: _.get(payload, "errorMessage"),
          }
        },
      }
    }
  },
  [accountActions.GET_ACCOUNTS_IN_GROUP_TABLE]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accounts, count } = payload;
    _.forEach(accounts, c => {
      _.set(c, "accountNo", _.get(c, "accountNumber"));
      _.set(c, "companyName", _.get(c, "accountDescription"));
      /*
        Status:
        true = Active
        false = Inactive
      */
      _.set(c, "activeStatus", c.status ? STATUSTYPE_AS_NAME[STATUS_NAME.ACTIVE].code : STATUSTYPE_AS_NAME[STATUS_NAME.INACTIVE].code);
      delete c["accountNumber"];
      delete c["accountDescription"];
      delete c["status"];
      delete c["businessArea"];
      delete c["groupName"];
      delete c["groupId"];
      delete c["sectorId"];
    });
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          groupDetails: {
            ...state.manageAccounts.groups.groupDetails,
            accountsTable: accounts,
            accountsCount: _.isNull(count) ? _.get(accounts, 'length') : count,
          }
        },
      }
    }
  },
  [accountActions.DELETE_GROUP]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          deletedGroupName: _.get(payload, "groupName"),
        },
      }
    }
  },
  [accountActions.DELETE_GROUP_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          errorTopic: _.get(payload, "errorTopic"),
          errorMessage: _.get(payload, "errorMessage"),
        },
      }
    }
  },
  [accountActions.SET_SELECTED_ACCOUNTS_IN_GROUP]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accountNo, isSelect } = payload;
    
    const targetRow = _.find(state.manageAccounts.groups.groupDetails.accountsTable, a => _.get(a, 'accountNo') === accountNo);
    if (targetRow)
      _.set(targetRow, "isSelected", isSelect);

    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          groupDetails: {
          ...state.manageAccounts.groups.groupDetails,
          accountsTable: [...state.manageAccounts.groups.groupDetails.accountsTable],
          },
        },
      }
    };
  },
  [accountActions.REMOVE_ACCOUNT_FROM_GROUP]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          groupDetails: {
            ...state.manageAccounts.groups.groupDetails,
            removedNoOfAccounts: _.get(payload, "noOfAccounts"),
            removedFromGroup: _.get(payload, "groupName"),
          },
        },
      }
    }
  },
  [accountActions.REMOVE_ACCOUNT_FROM_GROUP_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          errorTopic: _.get(payload, "errorTopic"),
          errorMessage: _.get(payload, "errorMessage"),
        },
      }
    }
  },
  [accountActions.UPDATE_GROUP_NAME_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          errorTopic: _.get(payload, "errorTopic"),
          errorMessage: _.get(payload, "errorMessage"),
        },
      }
    }
  },
  [accountActions.REASSIGN_GROUPS]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          groupDetails: {
            ...state.manageAccounts.groups.groupDetails,
            reassignedNoOfAccounts: _.get(payload, "noOfAccounts"),
            reassignedTo: _.get(payload, "groupName"),
          },
        },
      }
    }
  },
  [accountActions.REASSIGN_GROUPS_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          errorTopic: _.get(payload, "errorTopic"),
          errorMessage: _.get(payload, "errorMessage"),
        },
      }
    }
  },
  [accountActions.ASSIGN_GROUPS]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        assignedNoOfAccounts: _.get(payload, "noOfAccounts"),
        assignedToGroup: _.get(payload, "groupName"),
      }
    }
  },
  [accountActions.ASSIGN_GROUPS_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        errorTopic: _.get(payload, "errorTopic"),
        errorMessage: _.get(payload, "errorMessage"),
      }
    }
  },
  [accountActions.ASSIGN_ACCOUNTS_TO_GROUP]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          groupDetails: {
            ...state.manageAccounts.groups.groupDetails,
            assignedNoOfAccounts: _.get(payload, "noOfAccounts"),
          },
        },
      }
    }
  },
  [accountActions.ASSIGN_ACCOUNTS_TO_GROUP_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        groups: {
          ...state.manageAccounts.groups,
          errorTopic: _.get(payload, "errorTopic"),
          errorMessage: _.get(payload, "errorMessage"),
        },
      }
    }
  },
  [accountActions.EDIT_ELECTRICITY_ACCOUNTS_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        errorTopic: _.get(payload, "errorTopic"),
        errorMessage: _.get(payload, "errorMessage"),
      }
    }
  },
  [accountActions.UPDATE_MANUAL_ACCOUNT_IN_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          manualAddTable: payload,
        },
      }
    };
  },
  [accountActions.SET_SELECTED_AUTO_DETECTED_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accountNo, isSelect } = payload;

    const targetRow = _.find(state.manageAccounts.addAccounts.autoDetectedTable, a => _.get(a, 'accountNo') === accountNo);
    if (targetRow)
      _.set(targetRow, "isSelected", isSelect);

    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          autoDetectedTable: [...state.manageAccounts.addAccounts.autoDetectedTable],
        }
      }
    };
  },
  [accountActions.UPDATE_AUTO_DETECTED_TABLE]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accountNo, referenceId } = payload;
    const autoDetectedTable = _.get(state.manageAccounts.addAccounts, "autoDetectedTable", []);
    const targetIndex = _.findIndex(autoDetectedTable, (m:any) => m.accountNo === accountNo);
    _.set(state.manageAccounts.addAccounts, `autoDetectedTable[${targetIndex}].referenceId`, referenceId);

    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          autoDetectedTable: _.get(state.manageAccounts.addAccounts, "autoDetectedTable"),
        }
      }
    }
  },
  [accountActions.CLEAR_MANUAL_ACCOUNTS_IN_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          manualAddTable: [],
        }
      }
    }
  },
  [accountActions.CLEAR_AUTO_ACCOUNTS_IN_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          autoDetectedTable: [],
        }
      }
    }
  },
  [accountActions.UPLOAD_MANUAL_ACCOUNTS_IN_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const uploadAccounts = _.map(payload, p => {
      if (_.findIndex(state.manageAccounts.addAccounts.autoDetectedTable, (a:any) => a.accountNo === p.accountNumber) > -1)
        return {
          accountNo: p.accountNumber,
          referenceId: p.referenceID,
          accountNoHasError: true,
          accountNoErrorTypes: ["modules.bulkAccount.registration.errorOfDuplicate", ...p.errorTypeList],
          accountNoIsLoading: false
        };
      else
        return {
          accountNo: p.accountNumber,
          referenceId: p.referenceID,
          accountNoHasError: !_.isEmpty(p.errorTypeList),
          accountNoErrorTypes: p.errorTypeList,
          accountNoIsLoading: false,
        }
    });
    
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          uploadAccounts: [...uploadAccounts],
        }
      }
    }
  },
  [accountActions.VALIDATE_BULK_CHILD_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accountNumber, errorTypeList } = payload;
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          validatedAccount: {
            accountNo: accountNumber,
            errorType: errorTypeList,
          }
        }
      }
    }
  },
  [accountActions.ADD_ELECTRICITY_ACCOUNTS]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          noOfSuccessAccount: payload,
        }
      }
    }
  },
  [accountActions.ADD_ELECTRICITY_ACCOUNTS_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          errorTopic: _.get(payload, "errorTopic"),
          errorMessage: _.get(payload, "errorMessage"),
        }
      }
    }
  },
  [accountActions.GET_PENDING_CA]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { GetBulkChildAccountDetailsErrorModelDetails, count } = payload;

    const pendingCATable = _.forEach(GetBulkChildAccountDetailsErrorModelDetails, p => {
      _.set(p, "accountNoHasError", !!p.errorType && p.statusCode === pendingCAStatusCode.TAGGING);
      _.set(p, "status", p.errorType ? "error" : "pending");
      _.set(p, "uniqueId", _.uniqueId());
      _.set(p, "isSelected", false);
    });
    
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        pendingCA: {
          ...state.manageAccounts.pendingCA,
          pendingAccTable: [...pendingCATable],
          pendingAccCount: _.isNull(count) ? _.get(pendingCATable, 'length') : count,
        },
      }
    }
  },
  [accountActions.REMOVE_ACCOUNT_FROM_PENDING_CA]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        pendingCA: {
          ...state.manageAccounts.pendingCA,
          errorTopic: _.get(payload, "errorTopic"),
          errorMessage: _.get(payload, "errorMessage"),
        },
      }
    }
  },
  [accountActions.SUBMIT_PENDING_CA]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        pendingCA: {
          ...state.manageAccounts.pendingCA,
          noOfSuccessAccount: payload,
        },
      }
    }
  },
  [accountActions.REMOVE_ACCOUNT_FROM_PENDING_CA_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        pendingCA: {
          ...state.manageAccounts.pendingCA,
          errorTopic: _.get(payload, "errorTopic"),
          errorMessage: _.get(payload, "errorMessage"),
        }
      }
    }
  },
  [accountActions.SUBMIT_PENDING_CA_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        pendingCA: {
          ...state.manageAccounts.pendingCA,
          errorTopic: _.get(payload, "errorTopic"),
          errorMessage: _.get(payload, "errorMessage"),
        }
      }
    }
  },
  [accountActions.VALIDATE_BULK_CA_IN_PENDING_CA]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accountNumber, errorTypeList } = payload;
    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        pendingCA: {
          ...state.manageAccounts.pendingCA,
          validatedAccount: {
            accountNo: accountNumber,
            errorType: errorTypeList,
          }
        }
      }
    }
  },
  [accountActions.GET_AUTO_SUGGESTIVE_IN_ACCOUNT]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accounts } = payload;
    
    const existingAccount = _.get(state, "manageAccounts.addAccounts.autoDetectedTable", []);
    const existingSelectedAccount = _.filter(existingAccount, (a:any) => a.isSelected === true);
    _.forEach(accounts, (a:any) => {
      if (_.findIndex(existingSelectedAccount, (ea:any) => a.accountNo === ea.accountNo) > -1)
        _.set(a, "isSelected", true);
    });

    return {
      ...state,
      manageAccounts: {
        ...state.manageAccounts,
        addAccounts: {
          ...state.manageAccounts.addAccounts,
          autoDetectedTable: [...accounts],
        }
      }
    }
  },
  [accountActions.SET_REGISTRATION_USER_TYPE]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    const { accountType } = payload;

    return {
      ...state,
      registrationUserType: accountType,
    }
  },
  [accountActions.UPDATE_USER_PROFILE_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      myProfile: {
        ...state.myProfile,
        errorTopic: _.get(payload, 'errorTopic'),
        errorMessage: _.get(payload, 'errorMessage'),
      }
    };
  },
  [accountActions.GET_UPDATED_FAVDATES]: (state: IUpdateFavDates, { payload }: IPayloadObj) => {
    const { startDate, endDate } = payload;
    return {
      ...state,
      savedFavDates: {
        startDate:format(new Date(startDate), "yyyy-MM-dd"),
        endDate:format(new Date(endDate), "yyyy-MM-dd")
      }
    };
  },
  [accountActions.GET_FAVDATES]: (state: IgetFavDates, { payload }: IPayloadObj) => {
    const favDatePreference = payload.find((item: any) => item.preferenceCode === "favourite-date-preference");
  
    const startDate = favDatePreference?.startDate ?? null; 
    const endDate = favDatePreference?.endDate ?? null;     
  
    return {
      ...state,
      savedFavDates: {
        startDate,
        endDate
      }
    };
  },
  
  [accountActions.GET_REGISTRATION_DEFAULT_VALUES]: (state: IAccountReducer, { payload }: IPayloadObj) => {  
    const bulkAccountRegistration = state.bulkAccountRegistration;
    return {
      ...state,
      bulkAccountRegistration: {
        ...state.bulkAccountRegistration,
        companyName: _.isEmpty(_.get(bulkAccountRegistration, "companyName")) ? _.get(payload, "companyName", "") : _.get(bulkAccountRegistration, "companyName"),
        mobileNo: _.isEmpty(_.get(bulkAccountRegistration, "mobileNo")) ? _.get(payload, "mobileNo", "") : _.get(bulkAccountRegistration, "mobileNo"),
        mailUnitFloor: _.isEmpty(_.get(bulkAccountRegistration, "mailUnitFloor")) ? _.get(payload, "unitFloorNo", "") : _.get(bulkAccountRegistration, "mailUnitFloor"),
        mailBuildingName: _.isEmpty(_.get(bulkAccountRegistration, "mailBuildingName")) ? _.get(payload, "buildingName", "") : _.get(bulkAccountRegistration, "mailBuildingName"),
        mailStreetName: _.isEmpty(_.get(bulkAccountRegistration, "mailStreetName")) ? _.get(payload, "streetName", "") : _.get(bulkAccountRegistration, "mailStreetName"),
        mailCity: _.isEmpty(_.get(bulkAccountRegistration, "mailCity")) ? _.get(payload, "city", "") : _.get(bulkAccountRegistration, "mailCity"),
        mailPostal: _.isEmpty(_.get(bulkAccountRegistration, "mailPostal")) ? _.get(payload, "postalCode", "") : _.get(bulkAccountRegistration, "mailPostal"),
        mailState: _.isEmpty(_.get(bulkAccountRegistration, "mailState")) ? _.get(payload, "state", "") : _.get(bulkAccountRegistration, "mailState"),
        primaryContactName: _.isEmpty(_.get(bulkAccountRegistration, "primaryContactName")) ? _.get(payload, "primaryName", "") : _.get(bulkAccountRegistration, "primaryContactName"),
        primaryContactEmail: _.isEmpty(_.get(bulkAccountRegistration, "primaryContactEmail")) ? _.get(payload, "primaryEmail", "") : _.get(bulkAccountRegistration, "primaryContactEmail"),
        primaryContactMobileNo: _.isEmpty(_.get(bulkAccountRegistration, "primaryContactMobileNo")) ? _.get(payload, "primaryMobileNo", "") : _.get(bulkAccountRegistration, "primaryContactMobileNo"),
        // primaryContactJob: _.isEmpty(_.get(bulkAccountRegistration, "primaryContactJob")) ? _.get(payload, "primaryJobTitle", "") : _.get(bulkAccountRegistration, "primaryContactJob"),
        // primaryContactDepartment: _.isEmpty(_.get(bulkAccountRegistration, "primaryContactDepartment")) ? _.get(payload, "primaryDepartment", "") : _.get(bulkAccountRegistration, "primaryContactDepartment"),
        // secondaryContactName: _.isEmpty(_.get(bulkAccountRegistration, "secondaryContactName")) ? _.get(payload, "secondaryName", "") : _.get(bulkAccountRegistration, "secondaryContactName"),
        // secondaryContactEmail: _.isEmpty(_.get(bulkAccountRegistration, "secondaryContactEmail")) ? _.get(payload, "secondaryEmail", "") : _.get(bulkAccountRegistration, "secondaryContactEmail"),
        // secondaryContactMobileNo: _.isEmpty(_.get(bulkAccountRegistration, "secondaryContactMobileNo")) ? _.get(payload, "secondaryMobileNo", "") : _.get(bulkAccountRegistration, "secondaryContactMobileNo"),
        // secondaryContactJob: _.isEmpty(_.get(bulkAccountRegistration, "secondaryContactJob")) ? _.get(payload, "secondaryJobTitle", "") : _.get(bulkAccountRegistration, "secondaryContactJob"),
        // secondaryContactDepartment: _.isEmpty(_.get(bulkAccountRegistration, "secondaryContactDepartment")) ? _.get(payload, "secondaryDepartment", "") : _.get(bulkAccountRegistration, "secondaryContactDepartment"),
      }
    }
  },
  [accountActions.GET_REGISTRATION_DEFAULT_VALUES_FAILED]: (state: IAccountReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      bulkAccountRegistration: {
        ...state.bulkAccountRegistration,
        errorTopic: _.get(payload, "errorTopic"),
        errorMessage: _.get(payload, "errorMessage"),
      }
    }
  },
}

const accountReducer = (state: IAccountReducer = defaultState, { type, payload, error }: IReducerAction = { type: '' }) => {
  if (!type)
    return state;

  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
}
export default accountReducer;
export const REDUCER_ACOUNT = 'accountReducer'
